<template>
    <div class="enrichment-analysis">
        <div class="enrichment-analysis-con">
            <div class="enrichment-analysis-con-menu">
                <div class="enrichment-analysis-con-menu-item enrichment-analysis-con-menu-item-active">ENRICHMENT</div>
            </div>

            <div class="enrichment-analysis-con-d"></div>

            <div class="enrichment-analysis-con-main">
                <Enrichment />
            </div>
        </div>
    </div>
</template>

<script>
import Enrichment from "@/components/micRNA/Enrichment.vue";

export default {
    name: "LayoutMicRNAEnrichmentAnalysis",
    components: { Enrichment },
    data() {
        return {
            task: {},
            activeMenu: "enrichment",
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
.enrichment-analysis {
    height: 100%;
    background: #F9F9FB;
    padding: 1.4rem 8rem;

    &-con{
        display: flex;
        background: #fff;

        &-menu{
            width: 12.35rem;

            &-item{
                width: 100%;
                height: 64px;
                line-height: 64px;
                background: #fff;
                font-size: 19px;
                text-align: center;
                color: #666;
                font-weight: 500;
                cursor: pointer;

                &-active{
                    background: #097F35;
                    color: #fff;
                }
            }
        }

        &-d{
            width:0.65rem;
            background: #F9F9FB;
        }

        &-main{
            width: 66rem;
        }
    }
}
</style>